.not-yet-loaded {
  opacity: 0 !important;
}

/*  Intro   */

#intro > .intro-content {
  opacity: 0;
  transform: translateY(2em) scale(0.8);
  transition-property: opacity, transform;
  transition-duration: 1.65s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transition-delay: 0.2s;
}

#intro h2 {
  transition-delay: 0.5s;
}

#intro .scroll-icon {
  transition-delay: 0.7s;
}

#intro.in-view > .intro-content {
  transform: none;
  opacity: 1;
}

/*  Projects  */

.project {
  opacity: 0.25;
  transition-property: opacity;
  transition-duration: 0.77s;
  transition-delay: 0.25s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
}

.project .project-images img,
.project .project-info {
  opacity: 0.25;
  transform: translateY(50px) scale(0.8);
  transition-property: all;
  transition-duration: 0.85s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
}

.project .project-images .project-image-1 {
  transition-delay: 0.15s;
}

.project .project-images .project-image-2 {
  transition-delay: 0.25s;
}

.project.in-view,
.project.in-view .project-images img,
.project.in-view .project-info {
  transform: none !important;
  opacity: 1 !important;
}

/* Social Icons in Contact */

.social-icons {
  svg {
    transform: scale(0.9);
    will-change: transform;
    transition: transform 0.2s ease;
  }

  a:hover {
    svg {
      transform: scale(1);
    }
  }
}

/* Slide in/out transitions */

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #111;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #111;
  transform-origin: top;
}

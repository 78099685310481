@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --rsb-bg: #1d1a1e;
  --rsb-color: #fff;
  --rsb-ghost: #272727;
  --rsb-border: #2b2727;
}

.rsb-skin-light {
  --rsb-bg: #d9d9d9;
  --rsb-color: #0c0b0b;
  --rsb-ghost: #c5c2c2;
  --rsb-border: #c5c2c2;
  color: var(--rsb-color);
}

/* Minimal Reset */
* {
  box-sizing: border-box;
}

.background-text {
  text-align: center;
  display: inline-flex;
  margin: 0 auto;
  color: var(--rsb-bg);
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: var(--rsb-ghost);
  paint-order: stroke fill;
  z-index: 0;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.body {
  overflow-x: hidden;
}

@media (max-width: 1023px) {
  .container {
    max-width: 90vw;
  }
}

#intro {
  min-height: calc(100vh - 210px);
  /* Menu height + ~ line height of Work ghost text*/
}
#intro > * {
  z-index: 1;
}

#tsparticles {
  position: absolute;
  width: 100%;
  top: 0;
  height: calc(100% - 30px);
  overflow: hidden;
  z-index: 0;
}
#tsparticles > canvas {
  opacity: 0.4;
}

@media (max-width: 767px) {
  #intro h2 br {
    display: none;
  }
}

#intro .scroll-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid currentColor;
  border-radius: 100%;
}

#logos {
  height: 260vh;
  overflow: hidden;
}

/* About */

#about .container {
  max-width: 1400px;
}

@media (min-width: 1024px) {
  #about .pitch {
    font-size: 2rem;
    line-height: 2.75rem;
    margin-bottom: 14vh;
  }
}

#about h3 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.25em;
  margin-bottom: 27px;
}

.subtitle {
  margin-bottom: 6.5vh;
  line-height: 1.2em;
}

#about h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25em;
  margin-bottom: 20px;
}

#about ul {
  list-style: disc;
  margin-left: 20px;
}

#about p {
  padding-bottom: 1em;
}

.avatar {
  position: absolute;
  left: 40ch;
  max-width: 200px;
  filter: drop-shadow(2px 6px 3px rgba(20, 10, 0, 0.125));
}

@media (max-width: 1280px) {
  .avatar {
    left: 24ch;
    top: 17ch;
    max-width: 15ch;
  }
}

@media (max-width: 479px) {
  .avatar {
    left: 18ch;
  }
}

@media (min-width: 1024) and (max-width: 1440px) {
  .avatar {
    left: 30ch;
  }
}

#about a {
  position: relative;
}

#about a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: currentColor;
  width: 100%;
  height: 1px;
  transform: scale(1);
  transform-origin: 100%;
  transition: transform 0.2s ease;
}

#about a:hover:after {
  transform: scale(0);
}

.back-to-top {
  position: fixed;
  display: none;
  bottom: 50px;
  right: 50px;
  width: 62px !important;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  filter: drop-shadow(2px 6px 3px rgba(20, 10, 0, 0.125));
  z-index: 111;
  cursor: pointer;
  text-align: center;
  mix-blend-mode: difference;
}

.rsb-angle-btn {
  padding: 10px 20px;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  width: fit-content;
}

.rsb-angle-btn:not(.back-to-top) {
  position: relative;
  display: inline-block;
}

.rsb-angle-btn:before,
.rsb-angle-btn:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  color: inherit;
  border-color: currentColor;
  transform: scale(0.2, 0.4);
  transition: all 0.2s ease;
}

.rsb-angle-btn:before {
  top: 0;
  left: 0;
  border-width: 2px 0 0 2px;
  transform-origin: top left;
}

.rsb-angle-btn:after {
  bottom: 0;
  right: 0;
  border-width: 0 2px 2px 0;
  transform-origin: bottom right;
}

.rsb-angle-btn:hover::before,
.rsb-angle-btn:hover::after {
  transform: scale(1);
}

/*  Projects styles */
#work {
  overflow: hidden;
}

#work .background-text,
#past-work .background-text {
  top: 50vh;
  transform: translateY(-50%);
  text-align: center;
}

.featured-projects {
  position: relative;
}

.project:last-of-type {
  border-bottom: none;
}

@media (min-width: 1024px) {
  #work .project {
    width: 100vw;
    height: 100vh;
    display: flex;
    border-bottom: none;
    position: relative;
    overflow: hidden; /*Prevent blurry edges on backgrounds*/
  }

  .project-bg-image {
    position: absolute;
    top: -2.5vh;
    left: 0;
    width: 100vw;
    height: 105vh;
    z-index: 0;
    object-fit: cover;
    filter: blur(4px);
    opacity: 0.035;
    background-attachment: fixed;
    background-size: cover;
  }
}

#work .project .project-images {
  align-items: center;
  z-index: 2;
}

#work .project .project-info {
  justify-content: center;
  z-index: 2;
}

.project-image-1,
.project-image-2 {
  max-width: 100%;
}

.project.qode .project-image-1 {
  width: 100%;
  max-width: 880px;
}

@media (min-width: 768px) {
  .project-image-1,
  .project-image-2 {
    width: 40vw;
  }
}

@media (min-width: 1440px) {
  .project-image-1,
  .project-image-2 {
    width: 28vw;
    min-width: 360px;
    max-width: 560px;
  }

  .project.qode .project-images {
    width: calc(43.75vw + 40px);
    /*2x width + gap*/
  }
}

.project-title {
  font-size: 20px;
  font-weight: 900;
}

.project-meta {
  display: flex;
  color: #ccc;
}

.project-meta-font {
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

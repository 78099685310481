.logo-single {
  display: inline-flex;
  width: auto;
}

.logo-single img {
  width: 50%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.bg-fixer {
  pointer-events: none;
}

.logo-holder {
  display: grid;
  align-items: center;
  justify-items: center;
  min-height: 100vh;
  gap: 40px;
}

@media (min-width: 768px) {
  .logo-holder {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .logo-holder {
    grid-template-columns: repeat(3, 1fr);
  }
}

/*  Nav and mobile menu styles */

.site-header {
  --text-color: #fff;
  background: transparent;
  mix-blend-mode: difference;
  z-index: 100;
  position: sticky;
}

.desktop-nav,
.social-icons {
  flex: 1 1 100%;
}

.logo {
  display: flex;
  justify-content: center;
  z-index: 111;
}

.social-icons {
  display: none;
}

@media (min-width: 1024px) {
  .social-icons {
    display: flex;
    justify-content: flex-end;
  }
}

.desktop-nav {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.3em;
}

@media (min-width: 1440px) {
  .desktop-nav {
    font-size: 0.875rem;
  }
}

.mobile-menu {
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 0vh;
  overflow: hidden;
  top: 0;
  left: 0;
  background: rgba(10, 0, 5, 0.95);
  transition: height 0.4s ease-in-out, padding 0.4s ease;
}

.mobile-opener rect {
  transition: transform 0.2s ease-in-out;
  transform: translateY(0);
}

.mobile-opener.toggled-on rect:first-of-type {
  transform: translateY(-6px);
}

.mobile-opener.toggled-on rect:last-of-type {
  transform: translateY(6px);
}

.mobile-menu.toggled-on {
  height: 100vh;
  padding-top: 100px; /*Accommodate header too */
  overflow-y: auto;
}

.mobile-menu a {
  display: block;
  margin: 0.25em 0;
  padding: 0.5em 32px;
}
